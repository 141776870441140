// Privacy Policy
const PrivacyPolicy = [
  {
    content: `
        <h2>Definitions</h2>
        <p>
          ‘NONPERSONAL DATA’ (NPD) is information that is in no way personally
          identifiable.
        </p>
        <p>
          ‘PERSONAL DATA’ (PD) means any information relating to an identified
          or identifiable natural person (‘data subject’); an identifiable
          natural person is one who can be identified directly or indirectly by
          reference to an identifier such as a name, an identification number,
          location data, an online identifier, or to one or more factors
          specific to the physical, physiological, genetic, mental, economic,
          cultural, or social identity of that natural person. PD is in many
          ways the same as Personally Identifiable Information (PII). However,
          PD is broader in scope and covers more data. (GDPR) means General Data
          Protection Regulation.
        </p>
        <h2>Table of Contents</h2>
        <ul>
          <li>Your Rights</li>
          <li>Information We Collect and How We Collect It</li>
          <li>How Your Information Is Used and Shared</li>
          <li>Retaining and Destroying Your PD</li>
          <li>Updating Your PD</li>
          <li>Revoking Your Consent for Using Your PD</li>
          <li>Protecting the Privacy Rights of Third Parties</li>
          <li>Do Not Track Settings</li>
          <li>Links to Other Websites</li>
          <li>Protecting Children’s Privacy</li>
          <li>Our Email Policy</li>
          <li>Our Security Policy</li>
          <li>Use of Your Credit Card</li>
          <li>Transferring PD from the European Union</li>
          <li>Changes to Our Privacy Notice</li>
        </ul>
        <h2>Your Rights</h2>
        <p>
          When using our services and submitting PD to us, you may have certain
          rights under the GDPR if you reside or are in any of the countries of
          the European Union. Depending on the legal basis for processing your
          PD you may have some or all of the following rights:
        </p>
        <ul>
          <li>
            The Right to Be Informed&nbsp;<strong>–</strong>&nbsp;You have the
            right to be informed about the PD that we collect from you and how
            we process them.
          </li>
          <li>
            The Right of Access&nbsp;<strong>–</strong>&nbsp;You have the right
            to get confirmation that your PD are being processed and you have
            the ability to access your PD.
          </li>
          <li>
            The Right to Rectification – You have the right to have your PD
            corrected if they are inaccurate or incomplete.
          </li>
          <li>
            The Right to Erasure (Right to Be Forgotten)- You have the right to
            request the removal or deletion of your PD if there is no compelling
            reason for us to continue processing them.
          </li>
          <li>
            The Right to Restrict Processing – You have the right to ‘block’ or
            restrict the processing of your PD. When your PD are restricted, we
            are permitted to store your data, but not to process them further.
          </li>
          <li>
            The Right to Data Portability&nbsp;<strong>–</strong>&nbsp;You have
            the right to request your PD that you provided to us and use them
            for your own purposes. We will provide your data to you within 30
            days of your request. Contact us using the information at the top of
            this privacy notice to request your PD.
          </li>
          <li>
            The Right to Object&nbsp;<strong>–</strong>&nbsp;You have the right
            to object to us processing your PD for the following reasons:
          </li>
          <li>
            Processing was based on legitimate interests or the performance of a
            task in the public interest/exercise of official authority
            (including profiling)
          </li>
          <li>Direct marketing (including profiling)</li>
          <li>
            Processing for purposes of scientific/historical research and
            statistics
          </li>
          <li>
            Rights in relation to automated decision-making and profiling.
          </li>
          <li>
            Automated Individual Decision-Making and Profiling&nbsp;
            <strong>–</strong>&nbsp;You have the right not to be subject to a
            decision based solely on automated processing, including profiling,
            which produces legal effects regarding you or similarly
            significantly affects you.
          </li>
          <li>
            Filing a Complaint with Authorities&nbsp;<strong>–</strong>&nbsp;You
            have the right to file a complaint with supervisory authorities if
            your information has not been processed in compliance with the
            General Data Protection Regulation. If the supervisory authorities
            fail to address your complaint properly, you may have the right to a
            judicial remedy.
          </li>
        </ul>
        <h2>Information We Collect and How We Collect It</h2>
        <p>
          We may collect, use, store and transfer different kinds of data about
          you which we have grouped together as follows:
        </p>
        <ul>
          <li>
            Identity Data relating to you, any directors or shareholders of the
            business
          </li>
          <li>
            Contact Data relating to you, any directors or shareholders of the
            business&nbsp;
          </li>
          <li>
            Financial Data which includes bank account and payment card
            transaction details and information relating to your accounts and
            receivables.
          </li>
          <li>
            &nbsp;Credit Risk Agency Data includes information about your
            financial standing (including your credit score and repayment
            history)
          </li>
          <li>
            Fraud Activity Data obtained from relevant agencies on any fraud
            activity reported by other financial institutions which may include
            instances in which you were a victim of fraud
          </li>
          <li>
            Publicly Available Data includes that available at Companies House
            or equivalent, the Electoral Register together with other sources
          </li>
          <li>
            Transaction Data includes details about payments to and from you and
            other details of products and services we provide to you.
          </li>
          <li>
            Technical Data includes internet protocol (IP) address, your login
            data, browser type and version, time zone setting and location,
            browser plug-in types and versions, operating system and platform,
            and other technology on the devices you use to access the services
            we provide to you.
          </li>
          <li>Profile Data includes your username and password.</li>
          <li>
            Usage Data includes information about how you use our website,
            products and services.
          </li>
          <li>
            Third Party User Data includes email message bodies (including
            attachments), metadata, headers, and settings&nbsp;
          </li>
          <li>
            Marketing and Communications Data includes your preferences in
            receiving marketing from us and our third parties and your
            communication preferences.
          </li>
        </ul>
        <p>
          We use different methods to collect data from and about you including
          through:
        </p>
        <p>
          Direct interactions. You may give us your PD and NPD by filling in
          forms or by corresponding with us by post, phone, email or otherwise.
          This includes data you provide when you:
        </p>
        <ul>
          <li>register to use our website;</li>
          <li>subscribe to our services;</li>
          <li>
            participate in discussion boards or other social media functions on
            our website;
          </li>
          <li>request marketing to be sent to you;</li>
          <li>enter a competition, promotion or survey; or</li>
          <li>give us feedback or contact us.</li>
        </ul>
        <p>
          Automated technologies or interactions. As you interact with our
          website, we will automatically collect Technical Data about your
          equipment, browsing actions and patterns. We collect this personal
          data by using cookies, server logs and other similar technologies.
          Please see our
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://help.satago.com/en/articles/3946860-cookie-policy"
          >
            cookie policy
          </a>
          for further details.
        </p>
        <p>
          Third parties or publicly available sources. We will receive data
          about you from various third parties and public sources.
        </p>
        <h2>Our Legal Basis for Collecting and Processing PD</h2>
        <p>
          Our legal basis for collecting and processing your PD when you sign up
          for our newsletter, download free information, access free audios,
          videos, and webinars through our website, including information you
          enter using any of our opt-in forms is based on consent.
        </p>
        <h2>Automatic Information</h2>
        <p>
          We automatically receive information from your web browser or mobile
          device. This information may include the name of the website from
          which you entered our website, if any, as well as the name of the
          website you’ll visit when you leave our website. This information may
          also include the IP address of your computer/the proxy server you use
          to access the Internet, your Internet service provider’s name, your
          web browser type, the type of mobile device, your computer operating
          system, and data about your browsing activity when using our website.
          We use all this information to analyze trends among our users to help
          improve our website.
        </p>
        <h2 id="cookie-policy">When Entering and Using Our Website</h2>
        <p>
          When you enter and use our website and agree to accept cookies, some
          of these cookies may contain your PD.
        </p>
        <h2>Our Use of Cookies</h2>
        <p>
          Our website uses cookies. A cookie is a small piece of data or a text
          file that is downloaded to your computer or mobile device when you
          access certain websites. Cookies may contain text that can be read by
          the web server that delivered the cookie to you. The text contained in
          the cookie generally consists of a sequence of letters and numbers
          that uniquely identifies your computer or mobile device; it may
          contain other information as well.
        </p>
        <p>
          By agreeing to accept our use of cookies you are giving us and the
          third parties with which we partner permission to place, store, and
          access some or all the cookies described below on your computer.
        </p>
        <ul>
          <li>
            Strictly Necessary Cookies – These cookies are necessary for proper
            functioning of the website, such as displaying content, logging in,
            validating your session, responding to your request for services,
            and other functions. Most web browsers can be set to disable the use
            of cookies. If you disable these cookies, you may not be able to
            access features on our website correctly or at all.
          </li>
          <li>
            Performance Cookies – These cookies collect information about the
            use of the website, such as pages visited, traffic sources, users’
            interests, content management, and other website measurements.
          </li>
          <li>
            Functional Cookies – These cookies enable the website to remember
            users’ choices, such as their language, usernames, and other choices
            while using the website. They can also be used to deliver services,
            such as letting a user create a blog post, listen to audios, or
            watch videos on the website.
          </li>
          <li>
            Media Cookies – These cookies can be used to improve a website’s
            performance and provide special features and content. They can be
            placed by us or third parties who provide services to us.
          </li>
          <li>
            Advertising or Targeting Cookies – These cookies are usually placed
            and used by advertising companies to develop a profile of your
            browsing interests and serve advertisements on other websites that
            are related to your interests. You will see less advertising if you
            disable these cookies.
          </li>
          <li>
            Session Cookies – These cookies allow websites to link the actions
            of a user during a browser session. They may be used for a variety
            of purposes, such as remembering what a user has put in their
            shopping cart as they browse a website. Session cookies also permit
            users to be recognized as they navigate a website so that any item
            or page changes they make are remembered from page to page. Session
            cookies expire after a browser session; they are not stored long
            term.
          </li>
          <li>
            Persistent Cookies – These cookies are stored on a user’s device
            between browser sessions, which allows the user’s preferences or
            actions across a website or across different websites to be
            remembered. Persistent cookies may be used for several purposes,
            including remembering users’ choices and preferences when using a
            website or to target advertising to them.
          </li>
          <li>We may also use cookies for:</li>
          <li>identifying the areas of our website that you have visited</li>
          <li>personalizing content that you see on our website</li>
          <li>our website analytics</li>
          <li>remarketing our products or services to you</li>
          <li>remembering your preferences, settings, and login details</li>
          <li>
            targeted advertising and serving ads relevant to your interests
          </li>
          <li>affiliate marketing</li>
          <li>allowing you to post comments</li>
          <li>allowing you to share content with social networks.</li>
        </ul>
        <p>
          Most web browsers can be set to disable the use of cookies. However,
          if you disable cookies, you may not be able to access features on our
          website correctly or at all.
        </p>
        <h2>Web Beacons</h2>
        <p>
          We may also use a technology called web beacons to collect general
          information about your use of our website and your use of special
          promotions or newsletters. The information we collect by web beacons
          allows us to statistically monitor the number of people who open our
          emails. Web beacons also help us to understand the behaviour of our
          customers and users.
        </p>
        <h2>At Request for Additional Information</h2>
        <p>
          When you request the download of additional information via email, we
          collect your email address.
        </p>
        <h2>Google Analytics</h2>
        <p>
          Our website uses Google Analytics to collect information about the use
          of our website. Google Analytics collects information from users such
          as age, gender, interests, demographics, how often they visit our
          website, what pages they visit, and what other websites they have used
          before coming to our website. We use the information we get from
          Google Analytics to analyze traffic, improve our marketing,
          advertising, and website. Google Analytics collects only the IP
          address assigned to you on the date you visit our website, not your
          name or other identifying information. We do not combine the
          information collected using Google Analytics with PD. Although Google
          Analytics plants a permanent cookie on your web browser to identify
          you as a unique user the next time you visit our website, the cookie
          cannot be used by anyone but Google. Google also uses specific
          identifiers to help collect information about the use of our website.
        </p>
        <ul>
          <li>
            For more information on how Google collects and processes your data
            visit:&nbsp;
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://www.google.com/policies/privacy/partners/"
            >
              https://www.google.com/policies/privacy/partners/
            </a>
          </li>
          <li>
            You can prevent Google Analytics from using your information by
            opting out at this link:
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://tools.google.com/dlpage/gaoptout"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
          </li>
        </ul>
        <h2>Analytics</h2>
        <p>
          Our website uses analytics and crash reporting services from several
          companies to collect information about the use of our website.
          Analytics collects information such as how often users visit our
          website, what pages they visit, when they do so, what other websites
          they used before coming to our website, and their IP addresses. We use
          the information we get from analytics to improve our services.
        </p>
        <h2>Google Remarketing</h2>
        <p>
          Our website uses a remarketing advertising service. Our remarketing
          service is provided by Google and other companies that show our ads on
          websites across the Internet. With remarketing you may see ads for our
          products you have previously looked at. As an example, suppose you
          visit a website that sells computers, but you do not buy a computer on
          your first visit. The website’s owner might like to encourage you to
          revisit his/her website and buy a computer by showing you his/her ads
          again on other websites that you visit. We use remarketing for similar
          purposes. For this to happen Google will read a cookie that is already
          in your browser or place a cookie in your browser when you visit our
          website or other websites using remarketing. You can opt out of
          Google’s use of cookies and remarketing at this link:&nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://support.google.com/ads/answer/2662922?hl=en"
          >
            https://support.google.com/ads/answer/2662922?hl=en
          </a>
          &nbsp;or you can opt out using the Network Advertising Initiative opt
          out page at:&nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="http://optout.networkadvertising.org/#!/"
          >
            http://optout.networkadvertising.org/#!/
          </a>
        </p>
        <h2>Facebook Remarketing</h2>
        <p>
          Third parties, including Facebook, may use first-party cookies,
          third-party cookies, web beacons, and other storage technologies to
          collect or receive information from our services and elsewhere on the
          Internet, and use that information to provide measurement services and
          target ads. With Facebook remarketing you may see our ads on Facebook
          after you have used our services. For this to happen Facebook uses
          unique cookies that are activated and placed in a visitor’s browser
          when they land on a webpage. Facebook lookalike audience targeting
          allows us to show ads on Facebook to people who are similar to those
          who have already visited or made a purchase from our services. To opt
          out of Facebook’s collection and use of information for ad targeting
          visit:&nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.facebook.com/help/568137493302217"
          >
            https://www.facebook.com/help/568137493302217
          </a>
        </p>
        <h2>Remarketing</h2>
        <p>
          Our website and applications use remarketing advertising services.
          These remarketing services are provided by companies that show our ads
          on websites and devices across the Internet. With remarketing you may
          see ads for our products you have previously looked at. As an example,
          suppose you visit a website that sells computers, but you do not buy a
          computer on your first visit to that website. The website’s owner
          might like to encourage you to revisit their site and buy a computer
          by showing you their ads on other websites that you visit. We use
          remarketing for similar purposes. For this to happen remarketing
          companies will read a cookie in your browser. This can only happen if
          your browser is set to let it happen. You can opt out of these types
          of advertising cookies by visiting &nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="http://www.networkadvertising.org/choices"
          >
            http://www.networkadvertising.org/choices
          </a>
          .
        </p>
        <h2>What Happens If You Don’t Give Us Your PD</h2>
        <p>
          If you do not provide us with enough PD, we may not be able to provide
          you all our products and services. However, you can access and use
          some parts of our website without giving us your PD.
        </p>
        <h2>How Your Information Is Used and Shared</h2>
        <p>We will only use your data when the law allows us to.&nbsp;</p>
        <p>We use the information we receive from you to:</p>
        <ul>
          <li>to register you as a user</li>
          <li>to manage our relationship</li>
          <li>
            provide our products and services you have requested or purchased
            from us
          </li>
          <li>personalize and customize our content</li>
          <li>
            make improvements to our website, products, services, marketing,
            customer relationships, and experience
          </li>
          <li>
            contact you with updates to our website, products, and services
          </li>
          <li>resolve problems and disputes</li>
          <li>
            contact you with content, marketing and advertising that we believe
            may be of interest to you.
          </li>
          <li>
            to comply with a legal obligation including the avoidance of
            financial crime.
          </li>
          <li>
            where it is necessary for our legitimate interests provided it is
            necessary and is also in your interests.
          </li>
        </ul>
        <p>We may share your personal data with the parties set out below.</p>
        <p>
          Other companies in the Clear Factor Group who may provide you with
          access to elements of the Services.
        </p>
        <p>Service providers who provide payment processing services.</p>
        <p>
          Professional advisers including lawyers, bankers, auditors and
          insurers who provide consultancy, banking, legal, insurance and
          accounting services.
        </p>
        <p>
          HM Revenue &amp; Customs, regulators and other authorities who require
          reporting of processing activities in certain circumstances.
        </p>
        <p>
          Analytics and search engine providers who assist us in the improvement
          and optimisation of our website.
        </p>
        <p>
          Credit reference agencies and fraud prevention agencies who provide
          information on your company’s credit behaviour, and external funding
          partners.
        </p>
        <p>
          Third parties to whom we may choose to sell, transfer or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy policy.
        </p>
        <p>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We only permit them
          to process your PD for specified purposes and in accordance with our
          instructions.
        </p>
        <h2>Communications and Emails</h2>
        <p>
          When we communicate with you about our website, we will use the email
          address you provided when you registered as a user or customer. We may
          also send you emails with promotional information about our website or
          offers from us or our affiliates unless you have opted out of
          receiving such information. You can change your contact preferences at
          any time through your account or by contacting us using the contact
          information at the top of this privacy notice.
        </p>
        <h2>Sharing Information with Affiliates and Other Third Parties</h2>
        <p>
          We do not sell or rent your PD to third parties for marketing
          purposes. However, for data aggregation purposes we may use your NPD,
          which might be sold to other parties at our discretion. Any such data
          aggregation would not contain any of your PD. We may give your PD to
          third-party service providers whom we hire to provide services to us.
          These third-party service providers may include but are not limited to
          payment processors, web analytics companies, advertising networks,
          call centres, data management services, help desk providers,
          accountants, law firms, auditors, shopping cart and email service
          providers, and shipping companies.
        </p>
        <h2>Sharing Your PD for Lookalike or Similar Audience Marketing</h2>
        <p>
          We may share your PD with third parties for similar audience marketing
          purposes. Similar audience marketing is also called lookalike audience
          marketing. The third parties we share your PD with for this type of
          marketing include Facebook and/or Google. Using your PD for similar
          audience marketing or lookalike audience marketing helps us find new
          audiences (users and customers) based on similar interests to yours.
          This helps us improve our marketing services. Your PD is only shared
          with Facebook and Google for this type of marketing. By using our
          website and agreeing to our privacy notice you are giving consent for
          your PD to be used for the marketing purposes described within this
          section.
        </p>
        <h2>
          Sharing Your Information When You Login Using Social Media Websites
        </h2>
        <p>
          We may share your information with third parties such as Facebook.com,
          Twitter.com, YouTube.com, Instagram.com, and Google.com. If you decide
          to login to our website using these third parties or other social
          media websites, you are agreeing to let us use and store your profile
          information from those websites to make better use of any social media
          features on our website. This sharing of information helps us provide
          you a better experience when using our website and services. It also
          provides us with useful information such as visitor traffic. If you
          use any of the social sharing icons on our website to share our
          information, you may also be sharing your personal information through
          social media websites.
        </p>
        <h2>Sharing Information With Business Partners</h2>
        <p>
          We may share your PD with our business partners. The business partners
          include general business partners, affiliates, and joint venture
          partners. We share this information with them so that they can send
          you information about our products and services as well as their
          products and services. When you choose to take part in our services
          and/or offerings, you are authorizing us to provide your email address
          and other PD to our business partners. Please understand that when we
          share your PD with our business partners, your PD becomes subject to
          our business partners’ as well as our privacy notice.
        </p>
        <h2>Text Messaging and Push Notifications</h2>
        <p>
          If you provide a mobile telephone number to us, you are giving your
          consent and authorize us or a third party to send you text messages
          and push notifications. You are not required to give us your consent
          for these text messages and push notifications. However, withholding
          your consent may interfere or prevent us from providing some or all of
          our services to you. You can stop receiving text messages and push
          notifications at any time by contacting us.
        </p>
        <h2>Legally Required Releases of Information</h2>
        <p>
          We may be legally required to disclose your PD if such disclosure is
          (a) required by subpoena, law, or other legal process; (b) necessary
          to assist law enforcement officials or governmental enforcement
          agencies; (c) necessary to investigate violations of or otherwise
          enforce our terms and conditions; (d) necessary to protect us from
          legal action or claims from third parties, including you and/or other
          users; or (e) necessary to protect the legal rights, personal/real
          property, or personal safety of our company, users, employees, and
          affiliates.
        </p>
        <h2>Disclosures to Successors</h2>
        <p>
          If our business is sold or merges in whole or in part with another
          business that would become responsible for providing the website to
          you, we retain the right to transfer your PD to the new business. The
          new business would retain the right to use your PD according to the
          terms of this privacy notice as well as to any changes to this privacy
          notice as instituted by the new business. We also retain the right to
          transfer your PD if our company files for bankruptcy and some or all
          of our assets are sold to another individual or business.
        </p>
        <h2>Retaining and Destroying Your PD</h2>
        <p>
          We retain information that we collect from you (including your PD)
          only for as long as we need it for legal, business, or tax purposes.
          Your information may be retained in electronic, paper, or a
          combination of both forms. When your information is no longer needed,
          we will destroy, delete, or erase it.
        </p>
        <h2>Updating Your PD</h2>
        <p>
          You can update your PD using services found on our website. If no such
          services exist, you can contact us using the contact information found
          at the top of this privacy notice and we will help you. However, we
          may keep your PD as needed to enforce our agreements and to comply
          with any legal obligations.
        </p>
        <h2>Revoking Your Consent for Using Your PD</h2>
        <p>
          You have the right to revoke your consent for us to use your PD at any
          time. Such optout will not affect disclosures otherwise permitted by
          law including but not limited to: (i) disclosures to affiliates and
          business partners, (ii) disclosures to third-party service providers
          that provide certain services for our business, such as payment
          processors, web analytics companies, advertising networks, call
          centers, data management services, help desk providers, accountants,
          law firms, auditors, shopping cart and email service providers, and
          shipping companies, (iii) disclosures to third parties as necessary to
          fulfill your requests, (iv) disclosures to governmental agencies or
          law enforcement departments, or as otherwise required to be made under
          applicable law, (v) previously completed disclosures to third parties,
          or (vi) disclosures to third parties in connection with subsequent
          contests or promotions you may choose to enter, or third-party offers
          you may choose to accept. If you want to revoke your consent for us to
          use your PD, please contact us through the contact information at the
          top of this privacy notice.
        </p>
        <h2>Protecting the Privacy Rights of Third Parties</h2>
        <p>
          If any postings you make on our website contain information about
          third parties, you agree to make sure that you have permission to
          include that information. While we are not legally liable for the
          actions of our users, we will remove any postings about which we are
          notified, if such postings violate the privacy rights of others.
        </p>
        <h2>Do Not Track Settings</h2>
        <p>
          Some web browsers have settings that enable you to request that our
          website not track your movement within our website. Our website does
          not obey such settings when transmitted to and detected by our
          website. You can turn off tracking features and other security
          settings in your browser by referring to your browser’s user manual.
        </p>
        <h2>Links to Other Websites</h2>
        <p>
          Our website may contain links to other websites. These websites are
          not under our control and are not subject to our privacy notice. These
          websites will likely have their own privacy notices. We have no
          responsibility for these websites and we provide links to these
          websites solely for your convenience. You acknowledge that your use of
          and access to these websites are solely at your risk. It is your
          responsibility to check the privacy notices of these websites to see
          how they treat your PD.
        </p>
        <h2>Protecting Children’s Privacy</h2>
        <p>
          Even though our website is not designed for use by anyone under the
          age of 16, we realize that a child under the age of 16 may attempt to
          access our website. We do not knowingly collect PD from children under
          the age of 16. If you are a parent or guardian and believe that your
          child is using our website, please contact us. Before we remove any
          information we may ask for proof of identification to prevent
          malicious removal of account information. If we discover that a child
          is accessing our website, we will delete his/her information within a
          reasonable period of time. You acknowledge that we do not verify the
          age of our users nor have any liability to do so.
        </p>
        <h2>Our Email Policy</h2>
        <p>
          You can always opt out of receiving email correspondence from us or
          our affiliates.&nbsp;We will not sell, rent, or trade your email
          address to any unaffiliated third party without your permission except
          in the sale or transfer of our business, or if our company files for
          bankruptcy.
        </p>
        <h2>Our Security Policy</h2>
        <p>
          We have built our website using industry-standard security measures
          and authentication tools to protect the security of your PD. We and
          the third parties who provide services to us also maintain technical
          and physical safeguards to protect your PD. Unfortunately we cannot
          guarantee prevention of loss or misuse of your PD or secure data
          transmission over the Internet because of its nature. We strongly urge
          you to protect any password you may have for our website and not share
          it with anyone.
        </p>
        <h2>Use of Your Credit Card</h2>
        <p>
          You may have to provide a credit card to buy products and services
          from our website. We use third-party billing services and have no
          control over them. We use commercially reasonable efforts to ensure
          that your credit card number is kept strictly confidential by using
          only third-party billing services that use industry-standard
          encryption technology to protect your credit card number from
          unauthorized use. However, you understand and agree that we are in no
          way responsible for any misuse of your credit card number.
        </p>
        <h2>Transferring PD from the European Union</h2>
        <p>
          PD that we collect from you may be stored, processed, and transferred
          among any countries in which we operate. The European Union has not
          found the United States and some other countries to have an adequate
          level of protection of PD under Article 45 of the GDPR. Our company
          relies on derogations for specific situations as defined in Article 49
          of the GDPR. If you are a European Union customer or user, with your
          consent your PD may be transferred to the United States or other
          countries outside the European Union when you request information from
          us. When you buy goods or services from us, we will use your PD for
          the performance of a contract or to take steps to enter into a
          contract. Wherever we transfer, process, or store your PD, we will
          take reasonable steps to protect it. We will use the information we
          collect from you in accordance with our privacy notice. By using our
          website, services, or products you agree to the transfers of your PD
          described within this section.
        </p>
        <h2>Changes to Our Privacy Notice</h2>
        <p>
          We reserve the right to change this privacy notice at any time. If our
          company decides to change this privacy notice, we will post those
          changes on our website so that our users and customers are always
          aware of what information we collect, use, and disclose. If at any
          time we decide to disclose or use your PD in a method different from
          that specified at the time it was collected, we will provide advance
          notice by email sent to the email address on file in your account.
          Otherwise we will use and disclose our users’ and customers’ PD in
          agreement with the privacy notice in effect when the information was
          collected. In all cases your continued use of our website, services,
          and products after any change to this privacy notice will constitute
          your acceptance of such change. If you have questions about our
          privacy notice, please contact us through the information at the top
          of this privacy notice.
        </p>`,
  },
]

export default PrivacyPolicy
