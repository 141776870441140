import React from "react"

// store
import PrivacyPolicyContent from "../store/privacy-policy"

// components
import Layout from "../components/layout"

const PrivacyPolicy = () => (
  <Layout
    title="Privacy Policy"
    description="Our privacy notice tells you what personal data and nonpersonal data we may collect from you"
  >
    <section className="site-container py-32">
      <div className="terms-and-policy">
      <h2>Privacy Policy</h2>
      <div className="space-y-8 lg:space-y-0 lg:flex lg:space-x-10">
        <ol className="lg:w-1/2">
          <li>Effective Date: 15 April 2021</li>
          <li>Notice Version: 1.02</li>
          <li>Data Controller: Clear Factor Limited</li>
          <li>Company Name: Clear Factor Limited</li>
          <li>
            Company Address: Clear Factor Limited, Third Floor, 126-134 Baker Street, London W1U 6UE, United Kingdom
          </li>
          <li>
            Email:
            <a href="mailto:support@clearfactor.io">support@clearfactor.io</a>
          </li>
        </ol>
        <p className="lg:w-1/2">
          This document governs the privacy notice of our website&nbsp;
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="http://www.clearfactor.io/"
          >
            www.clearfactor.io
          </a>
          &nbsp;Our privacy notice tells you what personal data and nonpersonal
          data we may collect from you, how we collect them, how we protect
          them, how we share them, how you can access and change them, and how
          you can limit our sharing of them. Our privacy notice also explains
          certain legal rights that you have with respect to your personal data.
          Any capitalized terms not defined herein will have the same meaning as
          where they are defined elsewhere on our website.
        </p>
      </div>
      </div>
      <div
        className="terms-and-policy pt-12"
        dangerouslySetInnerHTML={{
          __html: PrivacyPolicyContent.map(i => i.content),
        }}
      />
    </section>
  </Layout>
)

export default PrivacyPolicy
